// eslint-disable-next-line
export default {
    input: {
        padding: 8
    },
    highlighter: {
        padding: 6
    },
    suggestions: {
        position: 'absolute',
        width: '100%',
        left: 0,
        top: 0,
        marginTop: 42,
        list: {
            borderRadius: 4,
            border: '1px solid rgba(0,0,0,0.15)',
            fontSize: 14,
            boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.24)',
            maxHeight: '285px',
            overflowX: 'hidden'
        }
    }
};
