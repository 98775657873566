import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import classNames from 'classnames';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import MenuItem from 'components/ui-components-v2/MenuItem';
import Menu from 'components/ui-components-v2/Menu';
import Icon from 'components/ui-components-v2/Icon';
import Button from 'components/ui-components-v2/Button';
import Translation from 'components/data/Translation';
import AvatarInfo from 'components/ui-components-cape/AvatarInfo';
import Tooltip from 'components/ui-components-v2/Tooltip';
import Dark from 'components/ui-components/Dark';
import DateFormat from 'components/ui-components/DateFormat';
import User from 'components/data/User';
import ConfirmDialog from 'components/ui-components/ConfirmDialog';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import CommentThreadReply from './comment-thread';
import Input from './input';
import UploadedFile from './uploaded-file';
import { Comment, CommentUser } from '../types/comments';
import { CommentsService } from '../services/comments.service';
import { CommentLines } from './comment-content';
import '../styles/comment-thread.scss';

interface Props {
    comment: Comment;
    campaignId: string;
    entity: string;
    getComments: () => void;
    displayOnly: boolean;
    users: CommentUser[];
    categories: { [key: string]: string };
    categoryKey: string;
    onChangeCategory: (value: string) => void;
    dark: boolean;
    commentItemSubIsResolved: boolean;
    indent: number;
    status: string; //should be boolean to isResolved when API v2
}

/**
 * CommentThread
 * Displays a CommentThread existing of one comment with replies. Includes input, editing, replying, resolving.
 */
const CommentThread = ({
    comment,
    campaignId,
    entity,
    getComments,
    displayOnly,
    users,
    categories,
    categoryKey,
    onChangeCategory,
    dark,
    commentItemSubIsResolved,
    indent,
    status
}: Props) => {
    const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);
    const [isReplyInputVisible, setIsReplyInputVisible] = useState(false);
    const [isEditInputVisible, setIsEditInputVisible] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [isCommentThreadExpanded, setIsCommentThreadExpanded] = useState(false);
    const [isCommentThreadResolved, setIsCommentThreadResolved] = useState(status ? true : false);

    /**
     * Sets the resolved status
     */
    const setResolved = (newIsCommentThreadResolved: boolean) => {
        if (isEditInputVisible) {
            toggleEditInputVisibility();
        }
        if (isReplyInputVisible) {
            toggleReplyInputVisibility();
        }
        setIsCommentThreadResolved(newIsCommentThreadResolved);
        updateResolvedStatus(newIsCommentThreadResolved);
    };

    /**
     * Calls the API to change resolve status
     */
    const updateResolvedStatus = (newIsCommentThreadResolved: boolean) => {
        const { fileUrl, fileName, text, parentId } = comment;

        let status = '';
        //This makes sure database (status: 'isResolved') is readable, can be deleted with new API releases.
        if (newIsCommentThreadResolved === true) status = 'isResolved';

        CommentsService.saveComment({
            id: comment.id,
            campaignId,
            entity,
            text,
            status,
            fileName,
            fileUrl,
            parentId
        })
            .then(() => {
                if (newIsCommentThreadResolved) {
                    SnackbarUtils.success(Translation.get('comments.commentResolved', 'common'));
                } else {
                    SnackbarUtils.success(Translation.get('comments.commentReopened', 'common'));
                }
            })
            .catch(() => {
                SnackbarUtils.error(Translation.get('feedback.errors.oops', 'common'));
                setIsCommentThreadResolved(!newIsCommentThreadResolved);
            });
    };

    /**
     * Delete a individual comment
     * Calls the API to delete it
     */
    const deleteComment = () => {
        CommentsService.deleteComment(comment.id, campaignId)
            .then(() => {
                getComments();
                SnackbarUtils.success(Translation.get('comments.commentRemoved', 'common'));
                setShowDeleteConfirmDialog(false);
            })
            .catch(() => {
                SnackbarUtils.error(Translation.get('feedback.errors.oops', 'common'));
            });
    };

    /**
     * Toggle the visibility of the edit input field.
     */
    const toggleEditInputVisibility = () => {
        setIsEditInputVisible(!isEditInputVisible);
        setIsReplyInputVisible(false);
        setAnchorEl(null);
    };

    /**
     * Toggle the visibility of the reply input field.
     */
    const toggleReplyInputVisibility = () => {
        setAnchorEl(null);
        setIsEditInputVisible(false);
        setIsReplyInputVisible(!isReplyInputVisible);
    };

    /**
     * Handles reply click, saving happens in the input
     */
    const handleClickReplyBtn = () => {
        toggleReplyInputVisibility();
        getComments();
        setIsCommentThreadExpanded(true);
    };

    /**
     * Handles edit click, saving happens in the input
     */
    const handleClickEditBtn = () => {
        toggleEditInputVisibility();
        getComments();
    };

    useEffect(() => {
        if (commentItemSubIsResolved && isEditInputVisible) {
            toggleEditInputVisibility();
        }
    }, [commentItemSubIsResolved]);

    return (
        <div
            data-mui-color-scheme={dark ? 'dark' : 'light'}
            className={classNames('comments__comment-thread', {
                'comments__comment-thread--dark': dark,
                'comments__comment-thread--indent': indent,
                [`comments__comment-thread--indent-${indent}`]: indent,
                'comments__comment-thread--resolved': isCommentThreadResolved
            })}>
            {showDeleteConfirmDialog && (
                <ConfirmDialog
                    open
                    title={Translation.get('comments.DeleteDialog.confirm', 'common')}
                    description={Translation.get('comments.DeleteDialog.description', 'common')}
                    onClose={() => setShowDeleteConfirmDialog(false)}
                    onConfirm={deleteComment}
                />
            )}
            <div className="comments__comment-thread__container__header">
                <AvatarInfo
                    size="small"
                    userName={comment.user.name}
                    userProfilePicture={comment.user.profilePicture}
                    caption={
                        <span>
                            <DateFormat type="fromNow" date={comment.dateEdit ? comment.dateEdit : comment.date} /> {comment.dateEdit ? '• (edited)' : ''}
                        </span>
                    }
                />
                <div className={classNames('comments__comment-thread__container__buttons', { 'comments__comment-thread__container__reply--dark': dark })}>
                    {!indent && !isCommentThreadResolved && (
                        <Tooltip title={Translation.get('comments.reply', 'common')} arrow>
                            <IconButton
                                size="small"
                                onClick={toggleReplyInputVisibility}
                                className="comments__comment-thread__container__buttons--small"
                                aria-label="reply">
                                <Icon fontSize="small">reply</Icon>
                            </IconButton>
                        </Tooltip>
                    )}
                    {!indent && (
                        <>
                            {isCommentThreadResolved && (
                                <Tooltip title={Translation.get('comments.markOpen', 'common')} arrow>
                                    <Button
                                        size="small"
                                        className="comments__comment-thread__container__buttons--font-lowercase"
                                        onClick={() => setResolved(!setIsCommentThreadResolved)}
                                        startIcon={<Icon>check_circle_outline</Icon>}>
                                        {Translation.get('comments.resolved', 'common')}
                                    </Button>
                                </Tooltip>
                            )}
                            {!isCommentThreadResolved && comment.user.id === User.get('id') && (
                                <Tooltip title={Translation.get('comments.markResolved', 'common')} arrow>
                                    <IconButton
                                        size="small"
                                        className="comments__comment-thread__container__buttons--small"
                                        color={isCommentThreadResolved ? 'success' : 'default'}
                                        onClick={() => setResolved(!isCommentThreadResolved)}
                                        aria-label="reply">
                                        <Icon fontSize="small">check_circle_outline</Icon>
                                    </IconButton>
                                </Tooltip>
                            )}
                        </>
                    )}

                    {!isCommentThreadResolved && !displayOnly && comment.user.id === User.get('id') && (
                        <Dark dark={dark}>
                            <Tooltip title={isCommentThreadResolved || commentItemSubIsResolved ? '' : Translation.get('labels.moreOptions', 'common')} arrow>
                                <span>
                                    <IconButton
                                        disabled={isCommentThreadResolved || commentItemSubIsResolved}
                                        className="comments__comment-thread__container__buttons--small"
                                        onClick={(event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)}
                                        size="small">
                                        <Icon fontSize="small">more_horiz</Icon>
                                    </IconButton>
                                </span>
                            </Tooltip>
                            <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
                                <MenuItem onClick={toggleEditInputVisibility}>
                                    <ListItemIcon>
                                        <Icon>edit</Icon>
                                    </ListItemIcon>
                                    <ListItemText primary={Translation.get('actions.edit', 'common')} />
                                </MenuItem>
                                <MenuItem onClick={() => setShowDeleteConfirmDialog(true)}>
                                    <ListItemIcon>
                                        <Icon>delete</Icon>
                                    </ListItemIcon>
                                    <ListItemText primary={Translation.get('actions.delete', 'common')} />
                                </MenuItem>
                            </Menu>
                        </Dark>
                    )}
                </div>
            </div>
            {comment.categoryLabel && (
                <div
                    onClick={() => comment.categoryKey && onChangeCategory && onChangeCategory(comment.categoryKey)}
                    className="comments__comment-thread__container__category">
                    <SubdirectoryArrowRightIcon color="inherit" fontSize="inherit" />
                    {comment.categoryLabel}
                </div>
            )}
            {isEditInputVisible && (
                <Input
                    dark={dark}
                    comment={comment}
                    campaignId={campaignId}
                    entity={entity}
                    onSave={handleClickEditBtn}
                    onCancel={toggleEditInputVisibility}
                    type="edit"
                    users={users}
                    categoryKey={categoryKey}
                    categories={categories}
                />
            )}

            {!isEditInputVisible && (
                <>
                    <div className="comments__comment-thread__container__body">
                        <CommentLines comment={comment} users={users} dark={dark} />
                    </div>
                    {comment.fileName && comment.fileUrl && <UploadedFile dark={dark} fileUrl={comment.fileUrl} fileName={comment.fileName} />}
                </>
            )}
            {isReplyInputVisible && (
                <Input
                    dark={dark}
                    campaignId={campaignId}
                    parentId={comment.id}
                    entity={entity}
                    onSave={handleClickReplyBtn}
                    onCancel={toggleReplyInputVisibility}
                    type="reply"
                    users={users}
                />
            )}

            {isCommentThreadExpanded && comment.subItems && comment.subItems.length > 0 && (
                <>
                    {comment.subItems.map((commentReply: Comment) => (
                        <CommentThreadReply
                            dark={dark}
                            key={'comment' + commentReply.id}
                            comment={commentReply}
                            campaignId={campaignId}
                            entity={entity}
                            getComments={getComments}
                            displayOnly={displayOnly}
                            users={users}
                            categoryKey={categoryKey}
                            categories={categories}
                            commentItemSubIsResolved={isCommentThreadResolved}
                            indent={1}
                        />
                    ))}
                </>
            )}

            {comment.subItems && comment.subItems.length > 0 && (
                <Button
                    className="comments__comment-thread__container__expand-button"
                    disabled={isCommentThreadResolved}
                    onClick={() => setIsCommentThreadExpanded(!isCommentThreadExpanded)}>
                    {isCommentThreadExpanded
                        ? Translation.get('labels.hide', 'common') + ' ' + Translation.get('comments.reactions', 'common', { count: comment.subItems.length })
                        : Translation.get('comments.reactionsWithCount', 'common', { count: comment.subItems.length })}
                </Button>
            )}
        </div>
    );
};

export default withRouter(CommentThread);
