import React, { useEffect, useState } from 'react';
import Request from 'components/data/Request';
import UserSelector from 'components/ui-components-cape/UserSelector';
import { Item } from 'components/ui-components-cape/UserSelector/interfaces/Item';

interface TeamSelectorProps {
    entityId: string;
    canEdit?: boolean;
    chrome?: boolean;
    maximumMembers?: number;
    includeTeams?: boolean;
}

const TeamSelector: React.FC<TeamSelectorProps> = ({ entityId, includeTeams = true }) => {
    const [users, setUsers] = useState<Item[]>([]);
    const [teams, setTeams] = useState<Item[]>([]);
    const [selectedUsers, setSelectedUsers] = useState<Item[]>([]);
    const [selectedTeams, setSelectedTeams] = useState<Item[]>([]);
    const [selectedMembers, setSelectedMembers] = useState<Item[]>([]);

    useEffect(() => {
        getTaggableUsersAndTeamsList();
        getTeam();
    }, []);

    const getTaggableUsersAndTeamsList = () => {
        Request.post('teams/taggableList', {}).then(
            (res: { data: { list: { people: React.SetStateAction<Item[]>; publicTeams: React.SetStateAction<Item[]> } } }) => {
                setUsers(res.data.list.people);
                if (includeTeams) {
                    setTeams(res.data.list.publicTeams);
                }
            }
        );
    };

    const removeMembers = (members: Item[]) => {
        members.forEach((member, index) => {
            Request.post('teams/removeTeamMember', { id: member.memberId }).then(() => {
                if (index === members.length - 1) {
                    getTeam();
                }
            });
        });
    };

    const addMembers = async (users: Item[], teams: Item[]) => {
        for (let index = 0; index < users.length; index++) {
            await Request.post('teams/addTeamMember', {
                entity: entityId,
                type: 'user',
                teamUserId: users[index].id
            });
        }

        for (let index = 0; index < teams.length; index++) {
            await Request.post('teams/addTeamMember', {
                entity: entityId,
                type: 'team',
                teamId: teams[index].id
            });
        }

        getTeam();
    };

    const getTeam = () => {
        Request.post('teams/get', {
            entity: entityId,
            type: 'team'
        }).then((res) => {
            const team = {
                ...res.data,
                members: res.data.members.reverse()
            };

            prepareValueForEntitySelector(team);
        });
    };

    const getArrayDifferences = (oldArray: Item[], newArray: Item[]) => {
        const removed = oldArray.filter((oldItem) => !newArray.some((newItem) => newItem.id === oldItem.id));
        const added = newArray.filter((newItem) => !oldArray.some((oldItem) => oldItem.id === newItem.id));

        return { removed, added };
    };

    const onMutation = (selectedMembers: Item[]) => {
        const newTeams: Item[] = [];
        const newUsers: Item[] = [];
        selectedMembers.forEach((item) => (item.itemType === 'user' ? newUsers.push(item) : newTeams.push(item)));

        const userMutationData = getArrayDifferences(selectedUsers, newUsers);
        const teamMutationData = getArrayDifferences(selectedTeams, newTeams);

        const addedUsers = userMutationData.added;
        const removedUsers = userMutationData.removed;

        const addedTeams = teamMutationData.added;
        const removedTeams = teamMutationData.removed;

        setSelectedUsers(newUsers);
        setSelectedTeams(newTeams);

        removeMembers([...removedUsers, ...removedTeams]);
        addMembers(addedUsers, addedTeams);
    };

    const prepareValueForEntitySelector = (data) => {
        const selectedMembers: Item[] = [];
        const selectedUsers: Item[] = [];
        const selectedTeams: Item[] = [];

        data.members.forEach((member) => {
            if (member.user || member.team)
                selectedMembers.push({ id: '', ...member.user, ...(member.team && member.team), itemType: member.type, memberId: member.id });
        });

        selectedMembers.forEach((item) => (item.itemType === 'user' ? selectedUsers.push(item) : selectedTeams.push(item)));

        setSelectedMembers(selectedMembers);
        setSelectedUsers(selectedUsers);
        setSelectedTeams(selectedTeams);
    };

    return (
        <div className="team-selector">
            <UserSelector users={users} teams={teams} value={selectedMembers} onMutation={onMutation} multiple avatarSize="x-large" />
        </div>
    );
};

export default TeamSelector;
