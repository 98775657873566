import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { IconButton, Tooltip } from '@mui/material';
import Badge from 'components/ui-components-v2/Badge';
import Icon from 'components/ui-components-v2/Icon';
import { CommentsService } from 'components/social/Comments/services/comments.service';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import Translation from 'components/data/Translation';
import '../styles/main.scss';

interface Props {
    className?: string;
    onOpen?: () => void;
    onClose?: () => void;
    onToggle?: () => void;
    title: string;
    entity: string;
    campaignId: string;
    size: 'small' | 'medium' | 'large';
    displayType: string;
}

const CommentReviewHeader = ({ className, onOpen, onClose, onToggle, campaignId, entity, title, size, displayType }: Props) => {
    const [numberOfMessages, setNumberOfMessages] = useState(0);
    const [status, setStatus] = useState<string | undefined>();
    const [open, setOpen] = useState(false);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        CommentsService.getComment(entity, campaignId, title)
            .then((res) => {
                if (!res) return;
                setNumberOfMessages(res.numberOfMessages);
                setStatus(res.status);
            })
            .catch(() => {
                SnackbarUtils.error(Translation.get('feedback.errors.oops', 'common'));
            });
    };

    const handleClick: React.MouseEventHandler<HTMLDivElement> = (event) => {
        if (open) {
            setOpen(false);
            onClose?.();
        } else {
            setOpen(true);
            onOpen?.();
        }

        if (onToggle) {
            onToggle();
        }

        loadData();
        event.preventDefault();
        event.stopPropagation();
    };

    const statusLabels = {
        review: 'In review',
        approved: 'Approved',
        declined: 'Declined'
    };

    if (displayType === 'display') {
        return (
            <div className={classNames('comment-review-header', className)}>
                <Tooltip title={Translation.get('comments.commentOpenTooltip', 'common')}>
                    <div className="comment-review-header__button" onClick={handleClick}>
                        {!open && (
                            <React.Fragment>
                                <IconButton size={size}>
                                    <Badge color="primary" badgeContent={numberOfMessages} invisible={Number(numberOfMessages) === 0}>
                                        <Icon>message</Icon>
                                    </Badge>
                                </IconButton>
                            </React.Fragment>
                        )}

                        {open && (
                            <IconButton size={size}>
                                <Icon>close</Icon>
                            </IconButton>
                        )}
                    </div>
                </Tooltip>

                <div className="comment-review-header__title">{title}</div>
                {status && <div className={'comment-review-header__status comment-review-header__status--' + status}>{statusLabels[status]}</div>}
            </div>
        );
    }

    return (
        <Tooltip title={Translation.get('comments.commentOpenTooltip', 'common')}>
            <div className={classNames('comment-review-header__button', `comment-review-header__button--${size}`, className)} onClick={handleClick}>
                {!open && (
                    <React.Fragment>
                        <IconButton size={size}>
                            <Badge color="primary" badgeContent={numberOfMessages} invisible={Number(numberOfMessages) === 0}>
                                <Icon>message</Icon>
                            </Badge>
                        </IconButton>
                    </React.Fragment>
                )}

                {open && (
                    <IconButton size={size}>
                        <Icon>close</Icon>
                    </IconButton>
                )}
            </div>
        </Tooltip>
    );
};

export { CommentReviewHeader };
