import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import CommentReviewHelpers from 'components/data/CommentReviewHelpers';
import { Comment, CommentUser } from '../types/comments';
import ProfileCard from './profile-card';
import { isValidUrl } from '../helpers/util';

interface CommentLinesProps {
    comment: Comment;
    users: CommentUser[];
    dark: boolean;
}

const CommentLines = ({ comment, users, dark }: CommentLinesProps) => {
    if (!comment || !comment.text) {
        return null;
    }

    const linesArray = comment.text.trim().split('\n');
    return (
        <div>
            {linesArray.map((line, index) => (
                <CommentWords line={line} key={`line-${index}`} users={users} dark={dark} />
            ))}
        </div>
    );
};

interface CommentWordsProps {
    line: string;
    users: CommentUser[];
    dark: boolean;
}

const CommentWords = ({ line, users, dark }: CommentWordsProps) => {
    if (!line) {
        return null;
    }

    const textArray: string[] = line.trim().split(' ');
    const wordsArray: { word: string; mention: boolean }[] = [];

    textArray.forEach((word) => {
        if (word[0] !== '@' || isValidUrl(word)) {
            wordsArray.push({ word, mention: false });
        } else {
            wordsArray.push({ word: word.substring(1), mention: true });
        }
    });

    return (
        <div>
            {wordsArray.map((word) => {
                if (isValidUrl(word.word)) {
                    return (
                        <span key={uuidv4()}>
                            <a className="comments__comment-thread__container__link" href={word.word} target="_blank" rel="noreferrer">
                                {word.word}
                            </a>
                            &nbsp;
                        </span>
                    );
                } else if (word.mention && CommentReviewHelpers.currentUser(word.word, users)) {
                    const user = CommentReviewHelpers.currentUser(word.word, users);
                    const key = uuidv4();

                    return (
                        <ProfileCard user={user} key={key} dark={dark}>
                            <span className="comments__comment-thread__container__mention">{CommentReviewHelpers.displayUser(word.word, users)}</span>
                        </ProfileCard>
                    );
                } else {
                    return <span key={uuidv4()}>{word.word}&nbsp;</span>;
                }
            })}
        </div>
    );
};

export { CommentLines, CommentWords };
