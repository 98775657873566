import React, { useState } from 'react';
import classNames from 'classnames';
import { defaultStyles, FileIcon } from 'react-file-icon';
import IconButton from '@mui/material/IconButton';
import Popper from '@mui/material/Popper';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import Link from 'components/ui-components-v2/Link';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Modal from '@mui/material/Modal';
import Paper from 'components/ui-components-v2/Paper';
import Icon from 'components/ui-components-v2/Icon';
import MenuItem from 'components/ui-components-v2/MenuItem';
import MenuList from 'components/ui-components-v2/MenuList';
import Translation from 'components/data/Translation';
import '../styles/uploaded-file.scss';

/**
 * UploadedFile
 * Displays a filename with icon. When hovering over and the file is an image, a preview option (modal) is available.
 */

interface Props {
    fileName: string;
    fileUrl: string;
    dark: boolean;
    onRemove?: () => void;
}

const UploadedFile: React.FC<Props> = ({ fileName, onRemove, fileUrl, dark }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    let shouldPopperClose = false;

    const openPopper = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const closePopper = () => {
        setAnchorEl(null);
    };

    const shouldPopperCloseOnTimer = (setShouldPopperClose) => {
        shouldPopperClose = setShouldPopperClose;
    };

    const startTimedClosePopper = () => {
        shouldPopperCloseOnTimer(true);
        setTimeout(() => {
            if (shouldPopperClose) {
                closePopper();
            }
        }, 5);
    };

    const openModal = () => setIsModalOpen(true);

    const closeModal = () => setIsModalOpen(false);

    const FileExtension = fileName.toLowerCase().split('.')[1];
    const fileNameNoExtension = fileName.toLowerCase().split('.')[0];
    let fileIconSpecs = {};
    if (defaultStyles[FileExtension]) {
        fileIconSpecs = defaultStyles[FileExtension];
    }

    const isPopperOpen = Boolean(anchorEl);

    return (
        <div className="comments__uploaded-file__flex">
            <a
                onMouseLeave={() => {
                    startTimedClosePopper();
                }}
                onMouseEnter={(event) => {
                    openPopper(event);
                }}
                href={fileUrl}
                className={classNames('comments__uploaded-file', { 'comments__uploaded-file--dark': dark })}>
                <div className="comments__uploaded-file__icon">
                    <FileIcon extension={FileExtension} {...fileIconSpecs} />
                </div>

                <div className="comments__uploaded-file__name"> {fileNameNoExtension}</div>
                <div className="comments__uploaded-file__extension">.{FileExtension}</div>
            </a>
            {onRemove && (
                <IconButton aria-label="delete" size="small" onClick={onRemove}>
                    <ClearRoundedIcon fontSize="inherit" />
                </IconButton>
            )}
            <Popper
                className="comments__uploaded-file__popper"
                onMouseEnter={() => {
                    shouldPopperCloseOnTimer(false);
                }}
                onMouseLeave={() => {
                    closePopper();
                }}
                open={isPopperOpen}
                anchorEl={anchorEl}>
                <Paper>
                    <MenuList>
                        <MenuItem component={Link} href={fileUrl}>
                            <ListItemIcon>
                                <Icon>download</Icon>
                            </ListItemIcon>
                            <ListItemText>{Translation.get('actions.download', 'common')}</ListItemText>
                        </MenuItem>
                        <MenuItem disabled={FileExtension === 'pdf'} onClick={openModal}>
                            <ListItemIcon>
                                <Icon>preview</Icon>
                            </ListItemIcon>
                            <ListItemText>{Translation.get('labels.preview', 'common')}</ListItemText>
                        </MenuItem>
                    </MenuList>
                </Paper>
            </Popper>
            <Modal open={isModalOpen} onClose={closeModal}>
                <Paper className="comments__uploaded-file__preview">
                    <div className="comments__uploaded-file__preview__top">
                        <div className="comments__uploaded-file__preview__top__filename">{fileName}</div>
                        <div>
                            <IconButton aria-label="download" component={Link} href={fileUrl}>
                                <Icon>download</Icon>
                            </IconButton>
                            <IconButton aria-label="close" onClick={closeModal}>
                                <Icon>clear_rounded</Icon>
                            </IconButton>
                        </div>
                    </div>
                    <img className="comments__uploaded-file__preview__img" src={fileUrl} />
                </Paper>
            </Modal>
        </div>
    );
};
export default UploadedFile;
