import React from 'react';
import classNames from 'classnames';
import Tooltip from 'components/ui-components/Tooltip';
import Divider from 'components/ui-components-v2/Divider';
import Chip from 'components/ui-components-v2/Chip';
import Avatar from 'components/ui-components-v2/Avatar';
import '../styles/profile-card.scss';

interface Props {
    children: JSX.Element;
    user: {
        departments: [];
        brands: [];
        markets: [];
        profilePicture: string;
        display: string;
    };
    dark: boolean;
}

/**
 * ProfileCard
 * Displays a card with username. And if available, brands, markets, departments and a profile picture.
 */
const ProfileCard: React.FC<Props> = ({ children, user, dark }) => {
    const userData = { Department: user.departments, Brand: user.brands, Market: user.markets };

    const toRender = Object.entries(userData).map(([key, value]) => {
        if (!value) {
            return null;
        }
        return (
            <div key={key}>
                <div className="profile-card__extra-info-title">{value.length > 1 ? key + 's' : key}</div>
                <div className="profile-card__chips">
                    {value &&
                        value.map((chipText) => {
                            return <Chip className="profile-card__chip" key={chipText} size="small" label={chipText} />;
                        })}
                </div>
            </div>
        );
    });
    return (
        <Tooltip
            classes={{ tooltip: classNames('profile-card', { 'profile-card--dark': dark }) }}
            title={
                <>
                    <div className="profile-card__top">
                        <Avatar className="profile-card__avatar" src={user.profilePicture}>
                            {user.display}
                        </Avatar>
                        <div className="profile-card__name">{user.display}</div>
                    </div>
                    <Divider />
                    {toRender.every((element) => element !== null) && <div className="profile-card__extra-info">{toRender}</div>}
                </>
            }>
            {children}
        </Tooltip>
    );
};
export default ProfileCard;
