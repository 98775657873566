import classNames from 'classnames';
import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { FormControl, InputLabel, OutlinedInput, Select } from '@mui/material';
import MenuItem from 'components/ui-components-v2/MenuItem';
import Dark from 'components/ui-components/Dark';
import EditorData from 'components/editor-data/EditorData';
import Translation from 'components/data/Translation';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import { UserProfileHelpers } from 'components/data/UserProfileHelpers';
import EmptyState from 'components/ui-components-cape/EmptyState';
import Illustration from 'components/ui-components-cape/Illustration';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import { CommentsHelpers } from 'helpers/comments.helpers';
import Input from './input';
import { CommentsService } from '../services/comments.service';
import { Comment, CommentUser } from '../types/comments';
import CommentThread from './comment-thread';
import '../styles/comments.scss';

interface Props {
    link?: string;
    dark?: boolean;
    displayOnly?: boolean;
    campaignId?: string | number;
    entity: string;
    onReloadData?: (comments: Comment[]) => void;
    onChangeCategory?: (categoryKey: string) => void;
    categoryKey?: string;
    categories?: { [key: string]: string };
    classes?: {
        root?: string;
    };
}

interface ComponentStoreProps {
    [entity: string]: Comment[];
}

const Comments = ({ dark = false, displayOnly = false, campaignId, entity, onReloadData, link, categoryKey, categories, onChangeCategory, classes }: Props) => {
    const [stateComments, setComments] = useState<Comment[]>([]);
    const { componentStoreComments } = useComponentStore<ComponentStoreProps>('Comments', {
        fields: {
            componentStoreComments: entity
        }
    });
    const [users, setUsers] = useState<CommentUser[]>([]);
    const inputRef = useRef<HTMLInputElement>(null);

    const { id, path, path2 } = useParams();

    useEffect(() => {
        getComments();
        getTaggableUsers();
    }, []);

    const entityConvert = (entityInput: string) => {
        let entity = (' ' + entityInput).slice(1);
        const flight = EditorData.get('flight');
        const language = EditorData.get('language');

        entity = entity.replace('[[flight]]', flight);
        entity = entity.replace('[[language]]', language);

        return entity;
    };

    /**
     * Get comments from the campaign/entity
     */
    const getComments = () => {
        if (!link) {
            // if link does not exists use id from the url params
            link = id;
            // if path param exists add it to the link
            if (path) {
                link = link + '/' + path;
            }
            // if path2 param exists add it to the link
            if (path2) {
                link = link + '/' + path2;
            }
        }

        // if entity is general, we see comments for all tabs
        if (entity === 'general') {
            CommentsService.getGeneralComments(entityConvert(entity), campaignId, link)
                .then((res) => {
                    if (!res) return;
                    setComments(res.comments);
                    if (onReloadData) {
                        onReloadData(res.comments);
                    }
                })
                .catch(() => {
                    SnackbarUtils.error(Translation.get('feedback.errors.oops', 'common'));
                });
            return;
        }

        CommentsHelpers.fetchComments(entityConvert(entity), campaignId, link, true);
    };

    const getTaggableUsers = () => {
        UserProfileHelpers.getUserProfiles().then((profiles) => {
            const users: CommentUser[] = profiles.map((profile) => {
                return {
                    display: profile.name,
                    id: profile.username,
                    profilePicture: profile.profilePicture,
                    brands: profile.brands,
                    departments: profile.departments,
                    markets: profile.markets
                };
            });

            setUsers(users);
        });
    };

    const comments = componentStoreComments ? componentStoreComments : stateComments;

    return (
        <div className={classNames('comments', classes?.root, { 'comments--dark': dark })}>
            {categories && Object.keys(categories) && Object.keys(categories).length > 0 && (
                <Dark dark={dark}>
                    <div data-mui-color-scheme={dark ? 'dark' : 'light'} className="comments__categories">
                        <FormControl fullWidth>
                            <InputLabel>{Translation.get('comments.selectFormat', 'common')}</InputLabel>
                            <Select
                                defaultValue="all"
                                value={categoryKey}
                                onChange={(event) => onChangeCategory && onChangeCategory(event.target.value)}
                                input={<OutlinedInput label="Select format" />}>
                                {Object.keys(categories).map((item, i) => (
                                    <MenuItem key={i} value={item}>
                                        {categories[item]}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                </Dark>
            )}

            {!displayOnly && (
                <div className="comments__input--top">
                    <Input
                        inputRef={inputRef}
                        dark={dark}
                        campaignId={campaignId}
                        entity={entityConvert(entity)}
                        type="comment"
                        onSave={() => getComments()}
                        users={users}
                        categoryKey={categoryKey === 'all' ? 'general' : categoryKey}
                        categories={categories}
                    />
                </div>
            )}

            <div className="comments__list">
                {comments
                    .filter((comment) => !categoryKey || categoryKey === 'all' || categoryKey === comment.categoryKey)
                    .sort((commentA, commentB) => {
                        if (commentA.status && !commentB.status) return 1;
                        else if (!commentA.status && commentB.status) return -1;
                        return 0;
                    })
                    .map((comment) => (
                        <CommentThread
                            dark={dark}
                            comment={comment}
                            campaignId={campaignId}
                            entity={entityConvert(entity)}
                            getComments={getComments}
                            key={'comment' + comment.id}
                            displayOnly={displayOnly}
                            users={users}
                            categoryKey={categoryKey}
                            categories={categories}
                            onChangeCategory={onChangeCategory}
                            status={comment.status}
                        />
                    ))}
            </div>

            {!comments.length && (
                <EmptyState
                    className="comments__empty-state"
                    illustration={<Illustration size="small" illustration="chat" />}
                    size="small"
                    primaryText={Translation.get('comments.noCommentsTitle', 'common')}
                    secondaryText={Translation.get('comments.noCommentsBody', 'common')}
                />
            )}
        </div>
    );
};

export { Comments };
